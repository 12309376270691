// JavaScript Document
$(document).ready(function(){
	//Navigation
	$('.right-pan-open').click(function(){
		if(!$('body').hasClass('right-slide-open')){
			$(this).addClass('active');
			$('body').addClass('right-slide-open');
			$('body').prepend('<div class="navigation-overlay"></div>');
		}else{
			$(this).removeClass('active');
			$('body').removeClass('right-slide-open');
			$('.navigation-overlay').remove();
		}
	});

	$(document).on( 'click', '.navigation-overlay, .close-right-pan', function() {
		$('.right-pan-open').removeClass('active');
		$('body').removeClass('right-slide-open');
		$('.navigation-overlay').remove();
	});

	//Datepicker
	$('.datetimepicker').datetimepicker({
		locale: 'fr',
		format: 'DD/MM/YYYY',
		extraFormats: [ 'DD/MM/YY' ]
	});

	//Left Question
	$('.question-close').click(function(){
		$(this).parent('.left-question-pan').remove();
	});

	//Upload file
	$('.input-file-link').click(function(){
		$(this).parent().children('.input-file').trigger('click');

	});

	// $('.input-file').change(function (){
	// 	var fileName = $(this).val();
	// 	$(this).parent().children('.upload-file').text(fileName);
	// });

	// Replace Class
	$('select.form-control').selectpicker();

	//Container Height
	containerHeight()

	//countdown
	/* $('.countdown').downCount({
	 date: '09/09/2016 12:00:00',
	 offset: +10
	 }, function () {

	 });*/

	//credit dropdown
	$('.credit-dropdown>li').click(function(){
		if($(this).children('ul').css('display')=='none'){
			$(this).addClass('active').children('ul').slideDown();
		}else{
			$(this).removeClass('active').children('ul').slideUp();
		}
	});

	$('.credit-dropdown li a').click(function(){
		var creditText	=	$(this).html();
		$('.credit-dropdown li').removeClass('active');
		$(this).parent('li').addClass('active');
		$(this).parents('.dropdown').children('.credit-drop-link').html(creditText);
	});

	//Auction slider
	var sync1 = $("#gallery-big");
	var sync2 = $("#gallery-thumb");

	sync1.owlCarousel({
		singleItem : true,
		slideSpeed : 1000,
		navigation: false,
		pagination:false,
		afterAction : syncPosition,
		responsiveRefreshRate : 200,
	});

	sync2.owlCarousel({
		items : 4,
		itemsTablet       : [768,4],
		itemsMobile       : [479,3],
		pagination:false,
		responsiveRefreshRate : 100,
		afterInit : function(el){
			el.find(".owl-item").eq(0).addClass("synced");
		}
	});

	function syncPosition(el){
		var current = this.currentItem;
		$("#gallery-thumb")
			.find(".owl-item")
			.removeClass("synced")
			.eq(current)
			.addClass("synced")
		if($("#gallery-thumb").data("owlCarousel") !== undefined){
			center(current)
		}
	}

	$("#gallery-thumb").on("click", ".owl-item", function(e){
		e.preventDefault();
		var number = $(this).data("owlItem");
		sync1.trigger("owl.goTo",number);
	});

	function center(number){
		var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
		var num = number;
		var found = false;
		for(var i in sync2visible){
			if(num === sync2visible[i]){
				var found = true;
			}
		}

		if(found===false){
			if(num>sync2visible[sync2visible.length-1]){
				sync2.trigger("owl.goTo", num - sync2visible.length+2)
			}else{
				if(num - 1 === -1){
					num = 0;
				}
				sync2.trigger("owl.goTo", num);
			}
		} else if(num === sync2visible[sync2visible.length-1]){
			sync2.trigger("owl.goTo", sync2visible[1])
		} else if(num === sync2visible[0]){
			sync2.trigger("owl.goTo", num-1)
		}

	}

	//Lightbox
	$(document).delegate('*[data-toggle="lightbox"]', 'click', function(event) {
		event.preventDefault();
		xx = null;
		$(this).ekkoLightbox({
			onContentLoaded: function() {
				$('.ekko-lightbox .modal-dialog').width(850);
			}
		});
	});



});
/**************************Document.ready end**************************/

$(window).scroll(function(){
	var leftQuestion	=	($('.header-part').outerHeight() + $('.banner-pan').outerHeight() + parseFloat($('.banner-pan').css('margin-bottom')));

	if ($(window).scrollTop() >= leftQuestion) {
		//alert('1');
		$('.left-question-pan').addClass('ques-fixed');
	}else{
		//alert('2');
		$('.left-question-pan').removeClass('ques-fixed');
	}

});

$(window).resize(function(){
	//Container Height
	containerHeight()
});



//Container Height
function containerHeight(){
	var winHeight		=	$(window).height();
	var	innerHeight	=	($('.header-part').outerHeight() + $('.banner-pan').outerHeight() + $('.footer-part').outerHeight() + parseFloat($('.banner-pan').css('margin-bottom')) + parseFloat($('.confirmation-container').css('margin-top')));
	var	editProHeight	=	($('.header-part').outerHeight() + $('.heading-pan').outerHeight() + $('.footer-part').outerHeight() + $('.heading-container').outerHeight() + parseFloat($('.heading-pan').css('margin-bottom')) + parseFloat($('.heading-container').css('margin-bottom')));

	var	contentHeight	=	winHeight - innerHeight;

	var editProHeight	=	winHeight - editProHeight;

	$('.confirmation-container').css({minHeight: contentHeight});
	$('.edit-profile-container').css({minHeight: editProHeight});
}



// Vertical centered modals
var modalVerticalCenterClass = ".modal";
function centerModals($element) {
	if($(window).width()>=1024){
		var $modals;
		if ($element.length) {
			$modals = $element;
		} else {
			$modals = $(modalVerticalCenterClass + ':visible');
		}
		$modals.each( function(i) {
			var $clone = $(this).clone().css('display', 'block').appendTo('body');
			var top = Math.round(($clone.height() - $clone.find('.modal-content').height()) / 2);
			top = top > 0 ? top : 0;
			$clone.remove();
			$(this).find('.modal-content').css("margin-top", top);
		});
	}else{
		//$('.modal-content').css("margin-top", '100px');
	}
}
$(modalVerticalCenterClass).on('show.bs.modal', function(e) {
	centerModals($(this));
});
$(window).on('resize', centerModals);







